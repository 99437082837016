import React from 'react'
import { useField } from 'formik'

import { Checkbox, Props as CheckboxProps } from '../Checkbox'

type Props = Omit<CheckboxProps, 'setValue' | 'value' | 'id'> & {
  name: string
  'data-testid'?: string
  label?: string
  className?: string
  disabled?: boolean
  children?: React.ReactNode
}

export const FormikCheckbox: React.FC<Props> = ({
  name,
  label,
  'data-testid': dataTestId,
  className,
  children,
  disabled,
  ...rest
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name)
  const isError = Boolean(error && touched)
  return (
    <Checkbox
      id={name}
      className={className}
      data-testid={dataTestId}
      label={label}
      setValue={setValue}
      value={value}
      disabled={disabled}
      error={isError ? error : undefined}
      {...rest}
    >
      {children}
    </Checkbox>
  )
}
FormikCheckbox.displayName = 'FormikCheckbox'
