import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { useField } from 'formik'
import { XIcon as X } from '@style-space/components/src/icons/XIcon'

import { pxToRem } from '../../utils/utils'
import { DatePicker } from '../common/DatePicker'
import { formatISO, getTime } from '../../lib/time'

const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;

    .label {
      text-transform: none;
      color: ${theme.color.dark};
      font-size: ${pxToRem(16)};
      font-family: ${theme.font.mukta};
      font-weight: ${theme.font.weight.regular};
      margin-bottom: ${pxToRem(14)};
    }

    input,
    .react-date-picker__inputGroup__leadingZero {
      font-family: ${theme.font.mukta};
      font-size: ${pxToRem(14)};
    }

    input,
    .react-date-picker__inputGroup__leadingZero,
    .react-date-picker__inputGroup__divider {
      color: ${theme.color.grey['800']};
      -webkit-text-fill-color: ${theme.color.grey['800']};
      opacity: 1;
    }

    .react-calendar__navigation {
      :disabled {
        background-color: ${theme.color.white};
      }
    }

    .react-date-picker__calendar {
      bottom: 100% !important;
      margin-top: 0 !important;
      top: auto !important;
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        position: absolute !important;
        left: 0 !important;
        transform: none;
        top: auto !important;
      }
    }
  `,
)

const StyledX = styled(X)`
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.625rem 0 0;
`

const Error = styled.div(
  ({ theme }) => css`
    margin: 0.5rem 0;
    display: block;
    line-height: 1;
    visibility: hidden;
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.red['500']};
    font-size: ${pxToRem(14)};

    &.error {
      visibility: visible;
    }

    &.pop-error {
      display: none;

      &.error {
        display: block;
      }
    }
  `,
)

type Props = PropsWithChildren<{
  name: string
  disabled?: boolean
  className?: string
  popError?: boolean
}>

export const BirthPicker: React.FC<Props> = ({
  className = '',
  name,
  disabled,
  popError,
  children = '',
}) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField<string>(name)
  const errorClass = touched && error ? 'error' : ''
  const popErrorClass = Boolean(popError) ? 'pop-error' : ''

  const onChange = React.useCallback((date: number | null) => {
    setValue(date ? formatISO(date) : '')
  }, [])

  React.useEffect(() => {
    if (value) {
      setTouched(true)
    }
  }, [value])

  return (
    <Wrap className={`${className}`}>
      <DatePicker
        className={className}
        defaultView="decade"
        defaultActiveStartDate={getTime('1995-05-06')}
        label={'Date of Birth'}
        value={value ? getTime(value) : undefined}
        disabled={disabled}
        minDate={getTime('1500-01-01')}
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        openCalendarOnFocus={false}
        yearPlaceholder="YYYY"
        setValue={onChange as any}
      />
      <Error className={`${errorClass} ${popErrorClass}`}>
        <StyledX red />
        {error}
      </Error>
      {children}
    </Wrap>
  )
}
