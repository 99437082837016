import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Error } from '../Error/Error'
import {
  SInput,
  SCheck,
  Wrap,
  InnerWrap,
  SLabel,
  Image,
} from './Checkbox.styles'
import { DefaultTheme } from '../../themes'

export type Props = {
  id: string
  'data-testid'?: string
  value: boolean
  label?: string
  disabled?: boolean
  error?: string | null
  popError?: boolean
  checkboxType?: 'default' | 'chip' | 'picture'
  setValue(checked: boolean): void
  className?: string
  checked?: boolean
  labelImg?: string
  children?: React.ReactNode
}

export const Checkbox: React.FC<Props> = ({
  id,
  label,
  value,
  disabled,
  'data-testid': dataTestId = 'checkbox-input',
  error,
  popError,
  className = '',
  labelImg,
  children,
  setValue,
  checkboxType = 'default',
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const errorClass = error ? 'error ' : ''
  const disabledClass = disabled ? 'disabled ' : ''
  const checkedState = value ? 'checked' : ''

  const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.checked)
  }

  return (
    <Wrap
      theme={theme}
      className={`checkbox ${className} ${checkboxType} ${checkedState}`}
      data-testid="checkbox"
    >
      <SLabel
        className={`${errorClass} ${disabledClass} ${checkedState}`}
        theme={theme}
        data-testid="checkbox-label"
      >
        {checkboxType == 'picture' && labelImg && (
          <Image
            // width={284}
            // height={330}
            theme={theme}
            src={labelImg}
            alt={id}
          />
        )}
        <SInput
          theme={theme}
          className={`${errorClass} ${disabledClass}`}
          id={id}
          data-testid={dataTestId}
          {...rest}
          onChange={onInputChange}
          checked={value}
          disabled={disabled}
          value={`${value}`}
          type="checkbox"
        />
        <SCheck theme={theme} className="check-icon" />
        <InnerWrap className={checkedState} theme={theme}>
          {label}
          {children}
        </InnerWrap>
      </SLabel>
      <Error error={error} popError={popError} />
    </Wrap>
  )
}
Checkbox.displayName = 'Checkbox'
