import styled, { css } from 'styled-components'
import NextImage, { ImageProps } from 'next/image'
import React from 'react'

import { pxToRem } from '../../lib/utils'
import { Check } from '../../icons/Check'
import { Theme } from '../../themes'

export const SInput = styled.input(
  ({ theme }) => css`
    cursor: pointer;
    flex: none;
    appearance: none;
    position: relative;
    margin: ${pxToRem(0, 6, 0, 0)};
    box-sizing: border-box;
    border: ${pxToRem(1)} solid ${theme.color.grey['300']};
    border-radius: ${theme.radius.small};
    padding: 0;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};

    :hover {
      border-color: ${theme.color.grey['400']};
    }

    :focus {
      outline: none;
    }

    :focus-visible {
      box-shadow: ${theme.boxShadow.focusBoxShadow};
    }

    :checked {
      background-color: ${theme.color.primary['500']};
      border-color: ${theme.color.primary['500']};
      :hover {
        border-color: ${theme.color.primary['400']};
        background-color: ${theme.color.primary['400']};
      }
      &.disabled {
        background: ${theme.color.grey['400']};
        border-color: ${theme.color.grey['400']};
      }
    }

    &.error {
      border-color: ${theme.color.red['500']};
      :hover {
        border-color: ${theme.color.red['700']};
      }
    }

    .picture & {
      position: absolute;
      top: ${pxToRem(16)};
      right: ${pxToRem(16)};
      background-color: ${theme.color.white};
      :checked {
        background-color: ${theme.color.primary['500']};
        border-color: ${theme.color.primary['500']};
        :hover {
          border-color: ${theme.color.primary['400']};
          background-color: ${theme.color.primary['400']};
        }
      }
    }

    :disabled {
      cursor: default;
      background: ${theme.color.grey['100']};
      border-color: ${theme.color.grey['300']};
    }

    .chip & {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: ${theme.radius.small};
      border: ${pxToRem(1)} solid ${theme.color.grey['300']};

      :hover {
        border: ${pxToRem(1)} solid ${theme.color.grey['400']};
      }

      :focus {
        outline: none;
      }

      :checked {
        background-color: ${theme.color.grey['800']};
        border-color: ${theme.color.grey['800']};

        :hover {
          border-color: ${theme.color.grey['700']};
          background-color: ${theme.color.grey['700']};
        }

        :after {
          visibility: hidden;
        }
      }

      :focus-visible {
        box-shadow: ${theme.boxShadow.focusBoxShadow};
      }
    }
  `,
)

export const SCheck = styled(Check)(
  ({ theme }) => css`
    position: absolute;
    cursor: pointer;
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    top: ${pxToRem(2)};
    left: ${pxToRem(2)};
    path {
      fill: ${theme.color.white};
      stroke: ${theme.color.white};
      stroke-width: 1;
    }
    .disabled > & {
      cursor: default;
      path {
        fill: ${theme.color.grey['100']};
        stroke: ${theme.color.grey['100']};
      }
    }

    .chip & {
      opacity: 0;
    }

    .picture & {
      position: absolute;
      top: ${pxToRem(18)};
      left: auto;
      right: ${pxToRem(24)};
    }
  `,
)

export const InnerWrap = styled.span(
  ({ theme }) => css`
    line-height: 1.5;
    font-size: ${pxToRem(16)};
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.regular};

    .chip & {
      position: relative;
      cursor: pointer;
      margin: 0;
      white-space: nowrap;
      background-color: transparent;
      padding: ${pxToRem(7, 15)};
      color: ${theme.color.grey['800']};
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      font-weight: ${theme.font.weight.regular};
      &.checked {
        color: ${theme.color.white};
      }
    }

    .picture & {
      position: absolute;
      bottom: ${pxToRem(16)};
      left: 0;
      padding: ${pxToRem(13, 10, 0)};
      width: 100%;
      height: auto;
      min-height: ${pxToRem(50)};
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      text-align: center;
      line-height: 1.3;
      font-size: ${pxToRem(18)};
      color: ${theme.color.white};
    }
  `,
)

export const Image: React.FC<ImageProps & { theme: Theme }> = styled(NextImage)(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    border-radius: ${theme.radius.medium};
  `,
) as any

export const Wrap = styled.div`
  position: relative;
  width: auto;
  height: auto;
  .input-error {
    margin: ${pxToRem(0, 0, 0, 26)};
  }
  &.picture {
    width: 100%;
    height: min-content;
    max-width: ${pxToRem(284)};
  }
`

export const SLabel = styled.label(
  ({ theme }) => css`
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;

    .picture & {
      cursor: pointer;
      display: flex;
      height: auto;
      width: auto;
      padding: 0;
      border: ${pxToRem(1)} solid transparent;
      border-radius: ${theme.radius.medium};
      overflow: hidden;
    }

    .checked.picture & {
      border-color: ${theme.color.primary['500']};
    }
  `,
)
