import React from 'react'
import styled, { css } from 'styled-components'
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker'
import ReactDatePicker from 'react-date-picker'
import {
  shiftDate,
  shiftDateToBrowser,
} from '@style-space/components/src/lib/time'

import { pxToRem } from '../../utils/utils'
import { Title } from './Title'

const Wrap = styled.div(
  ({ theme }) => css`
    .react-daterange-picker__calendar,
    .react-date-picker__calendar {
      bottom: auto !important;
      margin-top: ${pxToRem(50)};

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        position: fixed !important;
        left: 50% !important;
        transform: translateX(-50%);
        top: ${pxToRem(20)} !important;
      }
    }

    .react-daterange-picker__wrapper,
    .react-date-picker__wrapper {
      border: ${pxToRem(1)} solid ${theme.color.gray};
      border-radius: ${theme.radius.small};
      padding: ${pxToRem(9, 8, 9, 14)};

      .error & {
        border-color: ${theme.color.red['500']} !important;
      }
    }

    .react-daterange-picker__range-divider {
      margin: ${pxToRem(0, 4)};
    }

    .react-daterange-picker__inputGroup,
    .react-date-picker_inputGroup {
      flex-grow: 0;
      min-width: 0;

      :last-of-type {
        flex-grow: 1;
      }
    }

    .react-calendar__tile--rangeStart {
      border-bottom-left-radius: ${theme.radius.small} !important;
      border-top-left-radius: ${theme.radius.small} !important;
    }

    .react-calendar__tile--rangeEnd {
      border-bottom-right-radius: ${theme.radius.small} !important;
      border-top-right-radius: ${theme.radius.small} !important;
    }

    .react-calendar {
      border: none;
      box-shadow: ${theme.boxShadow.default};
      border-radius: ${theme.radius.small};
      padding: ${pxToRem(20)};
    }

    .react-calendar__tile {
      border-radius: 0;
      background-color: transparent;
      padding: ${pxToRem(4, 8)};
      margin: ${pxToRem(4, 0)};
      abbr {
        color: ${theme.color.dark};
      }

      :hover,
      :focus {
        background-color: ${theme.color.lighter};
      }

      :disabled {
        abbr {
          color: ${theme.color.light};
        }
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      abbr {
        color: ${theme.color.light};
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;
      abbr {
        color: ${theme.color.highlight};
      }
    }

    .react-calendar__tile--active {
      background-color: ${theme.color.blue} !important;

      :hover,
      :focus {
        background-color: ${theme.color.lightBlue};
      }

      abbr {
        color: ${theme.color.white};
      }
    }

    abbr {
      font-size: ${pxToRem(15)};
      font-weight: ${theme.font.weight.medium};
      text-decoration: none;
    }

    .react-calendar__navigation {
      height: auto;
      margin-bottom: ${pxToRem(40)};
      button {
        height: min-content;
      }

      .react-calendar__navigation__arrow {
        font-size: ${pxToRem(32)};
        line-height: ${pxToRem(32)};

        :disabled {
          color: ${theme.color.light};
          background-color: transparent;
        }
      }

      .react-calendar__navigation__label {
        > span {
          line-height: ${pxToRem(32)};
          font-size: ${pxToRem(16)};
          font-weight: ${theme.font.weight.semibold};
          font-family: ${theme.font.poppins};
        }
      }

      .react-calendar__navigation__next2-button,
      .react-calendar__navigation__prev2-button {
        display: none;
      }
    }
  `,
)

const STitle = styled(Title)(
  ({ theme }) => css`
    font-size: ${pxToRem(14)};
    line-height: 1;
    font-weight: ${theme.font.weight.semibold};
    letter-spacing: 0.04rem;
    color: ${theme.color.medium};
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: ${pxToRem(25)};

    .error & {
      color: ${theme.color.red['500']} !important;
    }
  `,
)

const SReactDateRangePicker = styled(ReactDateRangePicker)`
  border: none !important;
  width: 100%;
`

const SReactDatePicker = styled(ReactDatePicker)`
  border: none !important;
  width: 100%;
`

type Props = {
  label?: string
  description?: string
  disabled?: boolean
  range?: boolean
  defaultView?: 'decade'
  defaultActiveStartDate?: number
  value?: number | number[] | [number, number]
  minDate?: number
  maxDate?: number
  format?: string
  setValue: (value: number | number[]) => void
  onMonthViewChange?: (date: number) => void
  className?: string
  monthPlaceholder?: string
  dotFn?: (date: number) => boolean
  dayPlaceholder?: string
  openCalendarOnFocus?: boolean
  yearPlaceholder?: string
}

export const DatePicker: React.FC<Props> = ({
  className,
  label,
  disabled = false,
  value: valueNumber,
  format = 'MM/dd/yyyy',
  range,
  minDate,
  maxDate,
  defaultActiveStartDate,
  setValue,
  dotFn,
  onMonthViewChange,
  openCalendarOnFocus,
  dayPlaceholder = '--',
  monthPlaceholder = '--',
  yearPlaceholder = '--',
  ...rest
}) => {
  let value: undefined | Date | [Date, Date]
  if (valueNumber) {
    value = Array.isArray(valueNumber)
      ? (valueNumber.map(
          (item) => new Date(shiftDateToBrowser(item).valueOf()),
        ) as [Date, Date])
      : new Date(shiftDateToBrowser(valueNumber).valueOf())
  }
  const props = {
    minDate: minDate ? new Date(minDate) : undefined,
    maxDate: maxDate ? new Date(maxDate) : undefined,
    value,
    onChange: (date: Date | [Date, Date]) => {
      if (!date) {
        return
      }
      if (Array.isArray(date)) {
        const [minDate, maxDate] = date as Date[]
        setValue([shiftDate(minDate).valueOf(), shiftDate(maxDate).valueOf()])
        return
      }
      setValue(shiftDate(date).valueOf())
    },
    tileContent: ({ date, view }: any) => {
      return (
        <>
          {view === 'month' && dotFn && dotFn(shiftDate(date).valueOf()) && (
            <div className="abbr-dot" />
          )}
        </>
      )
    },
    ...rest,
  }
  return (
    <Wrap data-testid="datePicker" className={className}>
      {label && (
        <STitle data-testid="datePickerLabel" as="h3" className="label">
          {label}
        </STitle>
      )}
      {range ? (
        <SReactDateRangePicker
          {...props}
          disabled={disabled}
          clearIcon={null}
          onChange={props.onChange as any}
          openCalendarOnFocus={openCalendarOnFocus}
          locale="en-US"
          dayPlaceholder={dayPlaceholder}
          monthPlaceholder={monthPlaceholder}
          yearPlaceholder={dayPlaceholder}
          onActiveStartDateChange={({ activeStartDate, view }: any) => {
            if (onMonthViewChange && view === 'month') {
              onMonthViewChange(shiftDate(activeStartDate).valueOf())
            }
          }}
          onDrillDown={({ activeStartDate, view }: any) => {
            if (onMonthViewChange && view === 'month') {
              onMonthViewChange(shiftDate(activeStartDate).valueOf())
            }
          }}
          tileContent={({ date, view }: any) => {
            return (
              <>
                {view === 'month' && dotFn && dotFn(date) && (
                  <div className="abbr-dot" />
                )}
              </>
            )
          }}
          format={format}
          {...rest}
        />
      ) : (
        <SReactDatePicker
          {...props}
          onChange={props.onChange as any}
          disabled={disabled}
          clearIcon={null}
          locale="en-US"
          onActiveStartDateChange={({ activeStartDate, view }) => {
            if (!activeStartDate) {
              return
            }
            if (onMonthViewChange && view === 'month') {
              onMonthViewChange(shiftDate(activeStartDate).valueOf())
            }
          }}
          onDrillDown={({ activeStartDate, view }: any) => {
            if (onMonthViewChange && view === 'month') {
              onMonthViewChange(shiftDate(activeStartDate).valueOf())
            }
          }}
          openCalendarOnFocus={openCalendarOnFocus}
          dayPlaceholder={dayPlaceholder}
          monthPlaceholder={monthPlaceholder}
          yearPlaceholder={yearPlaceholder}
          format={format}
          defaultActiveStartDate={
            defaultActiveStartDate
              ? new Date(defaultActiveStartDate)
              : undefined
          }
        />
      )}
    </Wrap>
  )
}
DatePicker.displayName = 'DatePicker'
